import classnames from "classnames";
import React from "react";
// import logo from "./logo.png"
import { togglehomevideo } from  "../../../redux/actions/customizer/index";

import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Navbar, Modal, ModalBody, ModalHeader } from "reactstrap";
import img from "../../../assets/img/user.png";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import { history } from "../../../history";
import * as Icon from "react-feather";
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import { BsFillInfoCircleFill } from "@react-icons/all-files/bs/BsFillInfoCircleFill";

// import HorizontalLayout from "../../HorizontalLayout"
import Sidebar from "../../components/menu/horizontal-menu/HorizontalMenu";
import SmartHome from "../../../views/pages/SmartHome";

const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
const navbarTypes = ["floating", "static", "sticky", "hidden"];
// const ThemeNavbar = props => {

class ThemeNavbar extends React.Component {
  state = {
    modal: false,
  };
  componentDidMount() {
    // console.log(this.props.mdata);
    // console.log(this.props.mdata.fixed.name);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  toggleModal = () => {
    this.props.togglehomevideo(!this.props.homevideo);
  };
  render() {
    console.log("In navbar");
    console.log(this.props);
    var topbar = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    console.log("Test");
    console.log(topbar);
    return (
      <React.Fragment>
        <div className="content-overlay" style={{ padding: 177 }} />
        <div className="header-navbar-shadow" />
        <Modal
         isOpen={this.props.homevideo}
         toggle={this.toggleModal}
          keyboard={true}
          fade={true}
          centered={true}
          backdrop={true}
          contentClassName="nobackground"
        >
          <ModalHeader
            className="nobackground"
            toggle={this.toggleModal}
          ></ModalHeader>
          <ModalBody className="nobackground">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/rko2024.appspot.com/o/eventdata%2Fplatformimages%2Fcommunication.jpg?alt=media"
              width={"100%"}
            />
          </ModalBody>
        </Modal>
        <Navbar
          style={{ zIndex: 1000 }}
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light":
                this.props.navbarColor === "default" ||
                !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" &&
                this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" &&
                this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" &&
                this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" &&
                this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" &&
                this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" &&
                this.props.navbarType !== "static",
              "d-none":
                this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" &&
                  !this.props.horizontal) ||
                (!navbarTypes.includes(this.props.navbarType) &&
                  !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top":
                this.props.navbarType === "sticky" || this.props.horizontal,
              scrolling: this.props.horizontal && this.props.scrolling,
            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div
                  onClick={() => {
                    if (this.props.topdata && this.props.topdata.hideback) {
                      console.log("Show infor");
                      this.toggleModal()
                      // this.setState({ modal: true });
                    } else {
                      history.goBack();
                    }
                  }}
                  style={{ width: 30, padding: "1.4rem 0.5rem 1.35rem" }}
                >
                  {this.props.topdata && this.props.topdata.hideback ? (
                    // <NavbarBookmarks
                    //   topdata={this.props.topdata}
                    //   sidebarVisibility={this.props.sidebarVisibility}
                    //   handleAppOverlay={this.props.handleAppOverlay}
                    // />

                    <div style={{ color: "white" }}>
                      <BsFillInfoCircleFill size={20} />
                    </div>
                  ) : (
                    <div style={{ color: "white" }}>
                      <BiArrowBack size={20} />
                    </div>
                  )}
                </div>
                {/* <HorizontalLayout/> */}
                {/* <div
                  className="nav-search"
                  onClick={() => {
                    console.log("Toggle Video call status");
                    history.push("/page/lobby");
                  }}
                >
                  <div
                    id="meetingbutton"
                    className="nav-link-search"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon.Calendar
                      style={{ color: "#fff" }}
                      size={21}
                      data-tour="search"
                    />
                    <div style={{ color: "#fff" }}>Lobby</div>
                  </div>
                </div> */}
                {/* {this.props.horizontal ? ( */}
                <div
                  className="logo d-flex align-items-center"
                  style={{
                    color: "#fff",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  {/* <img
                    alt=""
                    width={50}
                    style={{ marginTop: isMobile ? 0 : 0 }}
                    src={"/images/ologo.png"}
                  /> */}
                  {this.props.topdata && this.props.topdata.title?<div style={{fontSize:"1.5rem"}}>{this.props.topdata.title}</div>:
                  <img src="/app_images/logo2.png" height={50} />}
                  {/* {this.props.topdata ? this.props.topdata.title : ""} */}
                  {/* {this.capitalizeFirstLetter(topbar.length>0?topbar:"Home")} */}
                </div>
                {/* ) : null} */}
                <NavbarUser
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  userName={
                    this.props.mdata
                      ? this.props.mdata.publicdata.name
                      : "Waiting"
                  }
                  userImg={
                    this.props.mdata
                      ? this.props.mdata.publicdata.pic
                        ? this.props.mdata.publicdata.pic
                        : img
                      : img
                  }
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.login.userRole,
    mdata: state.auth.login.data,
    eventdata: state.auth.login.eventdata,
    homevideo: state.customizer.customizer.homevideo,
  };
};

export default connect(mapStateToProps, {
  togglehomevideo,
})(ThemeNavbar);
