import React from "react";
// import * as Icon from "react-feather";
import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import {
  IoIosArrowBack
} from "@react-icons/all-files/io/IoIosArrowBack";

import { connect } from "react-redux";
import { NavItem, NavLink } from "reactstrap";
import { history } from "../../../history";




class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: [],
  };

  



  render() {
    let {
      // bookmarks: {  },
      sidebarVisibility,
    } = this.props;

    // const renderExtraStarred =
    //   extraStarred.length > 0
    //     ? extraStarred.map(i => {
    //         const IconTag = Icon[i.icon ? i.icon : null]
    //         return (
    //           <DropdownItem key={i.id} href={i.link}>
    //             <IconTag size={15} />
    //             <span className="align-middle ml-1">{i.title}</span>
    //           </DropdownItem>
    //         )
    //       })
    //     : null
    //1200 add d-xl-none to navebar-nav for toggle
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={()=>{
                if(this.props.topdata&&this.props.topdata.back){
                  history.goBack()
                }else{

                  sidebarVisibility()
                }
              
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {this.props.topdata && this.props.topdata.back ? (
                  <IoIosArrowBack
                    size={20}
                    style={{ color: "#fff" }}
                    className="ficon"
                  />
                ) : (
                  <FiMenu
                    size={20}
                    style={{ color: "#fff" }}
                    className="ficon"
                  />
                )}
                {/* <h4 style={{ color: "#fff",marginLeft:5 }}>Menu</h4> */}
              </div>
            </NavLink>
          </NavItem>
        </ul>
       
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookmarks: state.navbar,
  };
};

export default connect(mapStateToProps, null)(
  NavbarBookmarks
);
