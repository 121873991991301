// Initialize Firebase

import firebase from 'firebase/compat/app'
// import 'firebase/analytics'

import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

// import 'firebase/firestore';

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config =  {
  apiKey: "AIzaSyCL5-Xlf0Z0Ki0ABSsCRNgut3vNVF-9XVk",
  authDomain: "rko2024.firebaseapp.com",
  databaseURL: "https://rko2024-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rko2024",
  storageBucket: "rko2024.appspot.com",
  messagingSenderId: "695894659666",
  appId: "1:695894659666:web:fdb89de6ed25999bc4058b",
  measurementId: "G-0JYLBG56WE"
};
firebase.initializeApp(config)
// firebase.analytics();